/* eslint-disable jsx-a11y/alt-text */
import classNames from "classnames";
import React from "react";
interface Props {
  name?: string;
  url?: string;
  DocumentUrl?: string;
  classnamesvalues?: string;
}
import Download from "../images/download-button.svg";
export const ResearchComponent: React.FC<Props> = ({
  name,
  url,
  DocumentUrl,
  classnamesvalues,
}) => {
  return (
    <div
      className={classNames(
        "col-span-12 md:col-start-auto md:col-span-3 sm:col-span-4 ",
        "mt-60px lg:mt-0",
        classnamesvalues
      )}
    >
      <div className="w-mobile-12-cols md:w-tablet-4-cols lg:w-desktop-3-cols relative flex-grow-0 flex-shrink-0">
        <img src={url} alt="Logo" className="m-auto" />
      </div>
      {/* <p className="pt-20px text-center text-left ga-pdflist-title">{name}</p> */}
      <p className="pt-20px text-center text-left GA_insights_line_fix">
        {name}
      </p>
      <center>
        <a
          type="button"
          className="col-start-5 col-span-2  m-auto pb-40px"
          href={DocumentUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Download} className="w-100px h-60px" />
        </a>
      </center>
      <div></div>
    </div>
  );
};
